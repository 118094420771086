import { Container, Form, Row, Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PasswordStrengthBar from "react-password-strength-bar";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import LayoutLanding from "../components/LayoutLanding";
const eye = <FontAwesomeIcon icon={faEye} />;

export default function UserWaiting() {
  const [passwordShown, setPasswordShown] = React.useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [password, setPassword] = React.useState("");

  const handleSubmit = (e) => {
    let usernameinputvalue = document.getElementById("MyEmail1").value;
    let passwordinputvalue = document.getElementById("MyPassword1").value;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
      "Cookie",
      ".AspNet.Cookies=F8WF1tse1m4jPeVmq_y8v1PigUs5Dd7FfbcqJis39hCNEd1j5bfcb5Of5jeqAps4iebShIkJsGxTzQyGDEnIEqIUmZdG5PJzsXSHx2OS8UvrB-N8u1DhQeMksnPhA2IiV9j2GcDR-BIfGoJCvztFJRfnwSNhDftqKTUDcA68GTeji3c1fPA0VCIO7GdUeJ20Cu-VsZ5YqlTd7kjn15jHBF5xKy2_xuxvSy-UXNE_H9PEasutlUqZBrqVSOgRiAFC3aw_bZ9A1f-ER3L3apeVplyTs2gA_RbKd7JFhiKDiUvLHaBhWKbAu56hYy1_4CDnZBXqvGi5rdFe3siOTawWwlGj-qNhCStSuCDk2SL-DXmgktBbDYgpEF9KU_9Qv2zcahH5ncA1WUiFbkykRsowRvIXsOOxUIrtEcrEhi7DSv1ThsRP_MmGkK92wgjXcXwtAsUJOvK9VnrdWnGMOayErv02949RlnYs5oqHNiV_YVi7j9eC0jFAje45YrEoFrBgQJaHG0RIndTeD6GHaK3vQg"
    );

    var urlencoded = new URLSearchParams();
    urlencoded.append("password", passwordinputvalue);
    urlencoded.append("username", usernameinputvalue);
    urlencoded.append("grant_type", "password");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/token`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          navigate("/waitinglist");
        }
        return response?.text() ?? "";
      })

      .then((result) => {
        var json = JSON.parse(result);
        if (
          json?.error_description === "The user name or password is incorrect."
        ) {
          document.querySelector(".loginemailerror").style.display = "block";
        } else {
          document.querySelector(".loginemailerror").style.display = "none";
        }

        if (json.access_token) {
          localStorage.setItem("waitingtoken", json.access_token);
        }
      })
      .catch((error) => console.log("error", error));
    e.preventDefault();
  };

  return (
    <LayoutLanding>
      <main>
        <Container>
          <section>
            <div className="col-xl-7 col-lg-7 col-md-12 text-center mx-auto pt-4">
              <h1 className=" fw-800 mb-5 fs-44">
                Welcome to BOGO’s Waiting List!
              </h1>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 text-center mx-auto">
              <h5 className="fw-airlight lh-28 mt-3 mb-1">
                <span className="fw-airbold fw-800">
                  Our Invite-Only Program
                </span>
              </h5>
              <h5 className="fw-airlight lh-28 fs-18 mt-3 mb-5 px-xl-5 px-lg-5 px-md-4 px-0 ">
                offers thousands in savings opportunities and tons of rewards,
                perks and freebies!
              </h5>
            </div>
          </section>
          <section>
            <div className="col-xl-4 col-lg-4 col-md-12 pb-5 pt-3 mx-auto text-center">
              <form
                className="my-4 text-start"
                onSubmit={handleSubmit}
                action="/dashboard"
              >
                <p
                  className="loginemailerror"
                  style={{ display: "none", fontSize: "11px", color: "red" }}
                >
                  The user name or password is incorrect.
                </p>
                <div className="mb-2">
                  <Form.Group controlId="formGridEmail" className="mb-4">
                    <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                      E-mail address
                    </Form.Label>
                    <Form.Control id="MyEmail1" type="email" />
                  </Form.Group>

                  <Form.Group
                    controlId="formGridPassword"
                    className=" pass-wrapper"
                  >
                    <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                      Password
                    </Form.Label>
                    <Form.Control
                      id="MyPassword1"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type={passwordShown ? "text" : "password"}
                      required
                    />
                    <i
                      role="button"
                      onClick={togglePasswordVisiblity}
                      aria-hidden="true"
                    >
                      {eye}
                    </i>
                    <PasswordStrengthBar
                      scoreWordStyle={true}
                      barColors={false}
                      password={password}
                      shortScoreWord="Short"
                      className="text-uppercase"
                    />
                  </Form.Group>
                </div>
                <Button
                  variant="primary"
                  className="w-100 fs-14 fw-bold h-52"
                  type="submit"
                >
                  Submit
                </Button>
              </form>
            </div>
          </section>
        </Container>
      </main>
    </LayoutLanding>
  );
}
